@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
	font-family: 'Poppins', sans-serif;
	color: #151C29;
	/* font-size: 1rem; */
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: #151C29;
	font-weight: 400;
	margin-top: 0;
}

h1 i, h2 i, h3 i, h4 i, h5 i, h6 i,
.h1 i, .h2 i, .h3 i, .h4 i, .h5 i, .h6 i {
	color: #485364;
}

h1 {
	font-size: 2rem;
	font-weight: 600;
}

h2 {
	font-size: 1.75rem;
	font-weight: 600;
}

h3 {
	font-size: 22px;
	font-weight: 600;
}

h4 {
	font-weight: 600;
}

/* RESPONSIIIIVE */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 28px;
  }
  
  h2 {
    font-size: 22px;
  }
  
  h3 {
    font-size: 1.15rem;
  }
}

small {
	color: #485364;
}

strong, .bold, .strong {
	font-weight: 600;
}

p {
    color: #151C29;
    /* font-size: 1rem; */
		/* line-height: 160%; */
}

a {
	color: #0075FF;
	cursor: pointer;
	text-decoration: none !important;
}

a:hover, a:focus {
	color: #006acd;
	text-decoration: none !important;
}

.color-blue { color: #0075FF; }
.color-green { color: #2F9139; }
.color-orange { color: #FF7800; }
.color-orange.hover:hover { color: #E46511; }
.color-red { color: #BD1105; }
.color-dark-gray { color: #151C29; }
.color-gray { color: #485364; }

p > a {
    text-decoration: underline !important;
}
p > a:hover, p > a:focus {
    text-decoration: underline !important;
}

.color-red-hover, .color-red.hover {
	color: #BD1105;
}

.color-red-hover:hover, .color-red-hover:focus, .color-red.hover:focus, .color-red.hover:hover {
	color: #c91428;
}

@media screen and (max-width: 767px) {
    .left-mobile {
        text-align: right;
    }
    .center-mobile {
        text-align: center;
    }
    .right-mobile {
        text-align: right;
    }
}

.job-panel-head p,
.task-panel-head p,
.task-item p {
	margin: 0 !important;
	padding: 0 !important;
	font-size: inherit !important;
	font-weight: inherit !important;
	color: inherit !important;
}