::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #6a717c !important;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #6a717c !important;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: #6a717c !important;
}

::-ms-input-placeholder {
	/* IE 10+ */
	color: #6a717c !important;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: #6a717c !important;
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

.form-control:read-only {
	background: rgba(255, 255, 255, .8);
}

.form-control:read-only:focus {
	background: #fff;
	box-shadow: none !important;
	border: 2px solid #E3E5E9 !important;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    color: #0075ff;
    text-align: center;
    background-color: #f3f5fb;
    border: 2px solid #E3E5E9;
    border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
}

.form-control {
	border: 2px solid #E3E5E9;
	color: #151C29;
	box-shadow: none;
	border-radius: 12px;
	/*padding: 25px 15px 15px 15px !important;*/
	min-height: 38px;
	font-size: 1rem;
}

.form-control:focus {
	box-shadow: none !important;
	/* border: 1px solid #0075FF;
	box-shadow: inset 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 2px 5px 0 rgba(219, 225, 234, 0.5) !important; */
	border: 2px solid #0075FF;
	box-shadow: inset 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 0 0 3px rgba(102, 175, 233, .30) !important;
}

.checkbox label,
.radio label {
	min-height: 20px;
	padding-left: 0;
	margin-right: 20px;
	margin-bottom: 0;
	font-weight: 400;
	cursor: pointer;
}

.form-group {
	margin-bottom: 20px;
}

label {
	font-weight: 500;
	color: #151C29;
}

.optional {
	color: #B3BAC5;
	font-weight: 400;
}

.checkbox-label {
	color: #485364;
	font-weight: normal;
}

.transparent-select {
	background: transparent;
	border-radius: 2px;
	padding: 5px;
	outline: none;
	border: 2px solid #E3E5E9 !important;
	transition: .15s;
}

.transparent-select:focus {
	background: #fff;
	border: 1px solid rgba(0, 132, 255, 0.8) !important;
	box-shadow: inset 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 0 0 3px rgba(102, 175, 233, .30) !important;
}

.input-password {
	position: relative;
	background-image: none !important;
	padding: 0 !important;
}

.input-password .tooltip,
.input-password .tooltip-inner {
	left: 0;
	right: 0;
	width: 100%;
	max-width: 100%;
}

.input-password input {
	padding-right: 45px;
}

.input-password .feather {
	position: absolute;
	padding: 12px 15px;
	right: 0px;
	top: -3px;
	cursor: pointer;
	z-index: 2;
	touch-action: none;
}

.input-password.form-invalid .feather,
.input-password.form-valid .feather {
	right: 26px;
}

.form-invalid {
	border-color: #BD1105 !important;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNCAyNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGNzIyMzk7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO308L3N0eWxlPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMC4zLDMuOUwxLjgsMThjLTAuNiwxLTAuMiwyLjIsMC43LDIuN2MwLjMsMC4yLDAuNiwwLjMsMSwwLjNoMTYuOWMxLjEsMCwyLTAuOSwyLTJjMC0wLjMtMC4xLTAuNy0wLjMtMUwxMy43LDMuOWMtMC42LTAuOS0xLjgtMS4yLTIuNy0wLjdDMTAuNywzLjQsMTAuNSwzLjYsMTAuMywzLjl6Ii8+PGxpbmUgY2xhc3M9InN0MCIgeDE9IjEyIiB5MT0iOSIgeDI9IjEyIiB5Mj0iMTMiLz48bGluZSBjbGFzcz0ic3QwIiB4MT0iMTIiIHkxPSIxNyIgeDI9IjEyIiB5Mj0iMTciLz48L3N2Zz4=);
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: right 12px center;
	padding-right: 40px;
}

.form-invalid-message {
	display: inline-block;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	color: #BD1105;
	font-size: 12px;
	padding: 5px;
	padding-bottom: 0;
	animation-name: bounceIn;
	animation-duration: 600ms;
	animation-delay: 1s;
	font-weight: 500;
}

.form-valid {
	border-color: #2F9139 !important;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNCAyNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiMwOUNEMzM7fTwvc3R5bGU+PGc+PHBhdGggY2xhc3M9InN0MCIgZD0iTTksMThjLTAuMywwLTAuNS0wLjEtMC43LTAuM2wtNS01Yy0wLjQtMC40LTAuNC0xLDAtMS40czEtMC40LDEuNCwwTDksMTUuNkwxOS4zLDUuM2MwLjQtMC40LDEtMC40LDEuNCwwczAuNCwxLDAsMS40bC0xMSwxMUM5LjUsMTcuOSw5LjMsMTgsOSwxOHoiLz48L2c+PC9zdmc+);
	background-size: 18px;
	background-repeat: no-repeat;
	background-position: right 12px center;
	padding-right: 40px;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
	.form-invalid {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAVCAMAAABrN94UAAAAMFBMVEX////3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3Ijn3IjlucL9gAAAAD3RSTlMAECAwQFBgcICgsMDQ4PDg5yR0AAAAfklEQVR4AYWOWxLDIAwDBXk0hJC9/237Y081ITPdL8BYK/2nnIxNL3SAUxMbLCtMO2VwSAejyImnGDsVtgisD3OXWotDkh/ht+pmQcrcnIP0uxke/hS2llc3z9U91JVW47q8ZInFHER40Z4qp7NrzSRjuflIjRfuKtXOxFj1BWnBCFAJlJojAAAAAElFTkSuQmCC);
	}

	.form-valid {
		background-size: 14px;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAMAAACTkM4rAAAAElBMVEX///8JzTMJzTMJzTMJzTMJzTNn996EAAAABXRSTlMAEJCw0A13lbIAAAAzSURBVHjancoxDgAgDEJRKfX+VzZdwOAm088LKwZWSm8+0viXIvLDCYvSYpPYJDaJ7ZYDaNkA90bWiPUAAAAASUVORK5CYII=);
	}
}

.b {
	display: block;
}

.toggle {
	position: absolute;
	top: 0;
	left: 0;
	width: 36px;
	height: 20px;
	border-radius: 100px;
	background-color: #ddd;
	margin: 0;
	overflow: hidden;
	box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, .05);
}

.check:not(.feather) {
	position: absolute;
	display: block;
	cursor: pointer;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 6;
}

.check:checked~.track {
	box-shadow: inset 0 0 0 20px #2F9139;
}

.check.urgent:checked~.track {
	box-shadow: inset 0 0 0 20px #C7700A;
}

.check.autopilot:checked~.track {
	box-shadow: inset 0 0 0 20px #0075ff;
}

.check:checked~.switch {
	right: 2px;
	left: 18px;
	transition: .15s cubic-bezier(0.785, 0.135, 0.150, 0.860);
	transition-property: left, right;
	transition-delay: .05s, 0s;
}

.switch-toggle {
	display: flex;
	align-items: center;
	height: 20px;
}

.toggle-wrapper {
	width: 36px;
	height: 20px;
	display: inline-block;
	position: relative;
	margin-right: 5px;
}

.toggle-wrapper input {
	margin: 0 !important;
}

label .toggle-wrapper {
	float: right;
	margin-left: 5px;
	;
}

.switch {
	position: absolute;
	left: 2px;
	top: 2px;
	bottom: 2px;
	right: 18px;
	background-color: #fff;
	border-radius: 36px;
	z-index: 1;
	transition: .15s cubic-bezier(0.785, 0.135, 0.150, 0.860);
	transition-property: left, right;
	transition-delay: 0s, .05s;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
}

.track {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	transition: .15s cubic-bezier(0.785, 0.135, 0.150, 0.860);
	border-radius: 40px;
}

.form-line {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.form-line>* {
	margin-right: 10px;
}

.form-line>*:last-child {
	margin: 0;
}

.form-line .form-control {
	width: auto;
}

[contenteditable] {
	cursor: text;
	color: #151C29;
	transition: .15s all;
	border: 2px solid transparent;
	border-radius: 8px;
}

[contenteditable="true"]:hover {
	/* background: #f6f7f999; */
	border: 2px solid #E3E5E9;
}

[contenteditable="true"]:focus {
	background: transparent !important;
	border: 2px solid #0075FF !important;
	box-shadow: inset 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 2px 5px 0 rgba(219, 225, 234, 0.5), 0 0 0 3px rgba(102, 175, 233, .30) !important;
}

[contenteditable="true"]:empty:before {
	content: attr(placeholder);
	color: #6a717c;
}