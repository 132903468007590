.table-borderless {
    border: none !important;
}

.table-borderless * {
    border: none !important;
    vertical-align: middle !important;
}

.table-borderless td:first-child {
    border-radius: 3px 0 0 3px !important;
}

.table-borderless td:last-child {
    border-radius: 0 3px 3px 0 !important;
    padding-right: 15px;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f6f7fa;
}

th {
    font-weight: 500;
    color: #151C29;
}

.table a {
    padding: 6px 0;
    display: inline-block;
}

.table-responsive {
    border: none;
}

@media screen and (min-width:992px) {
    .table-responsive {
        overflow: visible !important;
    }
}

.table tbody tr:hover {
    /* border: 1px solid #0075FF !important; */
    /* box-shadow: 0 0 5px #0075FF; */
    outline: 2px solid rgba(102, 175, 233, .40);
    background: rgba(102, 175, 233, .10);
}

/* .table-assigments td {
    border: 1px solid #EBECF0 !important;
} */

.table-assigments tbody tr:hover {
    /* border: 1px solid #0075FF !important; */
    /* box-shadow: 0 0 5px #0075FF; */
    outline: none;
    background: inherit;
}

.table-assigments tbody>tr:nth-of-type(odd):hover {
    /* border: 1px solid #0075FF !important; */
    /* box-shadow: 0 0 5px #0075FF; */
    outline: none;
    background: #f6f7fa;
}


.table-assigments .check {
    color: transparent;
    font-size: 20px;
}

.table-assigments .check.active {
    color: #B3BAC5;
    font-size: 20px;
}

.table-assigments tbody td:nth-child(2) .check.active {
    color: #0075FF;
    font-weight: 600;
    font-size: 20px;
}

.modal-body-assignments {
    background: #fff;
    overflow: auto;
    position: relative;
}

/* .modal-content {
    overflow: hidden;
} */

.table-assigments {
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    margin: -15px 0;
}

thead.fixed {
    position: absolute !important;
    top: 0;
    padding: 7px 0px;
    background: #fff;
    border-bottom: 2px solid rgba(39, 76, 132, 0.1) !important;
    z-index: 2;
    opacity: 0;
    /* pointer-events: none; */
    cursor: default !important;
}

.thead-left {
    position: absolute;
    left: 0px;
    top: 56px;
    opacity: 0;
    z-index: 1;
    cursor: default;
    transform: translate3d(-2px,0,0);
}

.thead-left div {
    padding: 12px;
    line-height: 1.42857143;
    font-weight: 500;
    background: #fff;
    height: 46px;
}

.border-right-fixed {
    border-right: 2px solid rgba(39, 76, 132, 0.1) !important;
}

.thead-left div:nth-of-type(odd) {
    background-color: #f2f2f2;
}

.table-assigments thead th {
    padding: 10px 0;
}


.table-assigments tbody th {
    position: relative;
}

.table-assigments th {
    white-space: nowrap;
}

.table-assigments thead.default th {
    padding-top: 15px;
    padding-bottom: 17px;
}

.table-assigments tbody td:nth-child(2):hover {
    cursor: pointer;
}

.table-assigments tbody td:nth-child(2):hover .check {
    color: #B3BAC5;
    font-weight: 600;
}

.table-assigments tbody td:nth-child(2):hover .check.active:before {
    color: #0075FF;
    font-weight: 600;
}

.table-assigments tbody td:hover {
    background: rgba(102, 175, 233, .1);
}

.table-assigments td,
.table-assigments th,
.table-assigments tr {
    position: relative;
}

.table-assigments td:hover:after,
.table-assigments th:hover:after {
    content: "";
    position: absolute;
    outline: 2px solid rgba(102, 175, 233, .40);
    background: rgba(102, 175, 233, .05);
    left: 0;
    top: -5000px;
    height: 10000px;
    width: 100%;
    z-index: 10;
    pointer-events: none;
}

.table-assigments tbody tr:hover td:nth-child(2):before {
    content: "";
    position: absolute;
    outline: 2px solid rgba(102, 175, 233, .40);
    background: rgba(102, 175, 233, .05);
    height: 44px;
    top: 0; left: -5000px;
    width: 10000px;
    /* width: 100%; */
    z-index: 10;
    pointer-events: none;
}

.table-assigments th:hover:after,
thead.fixed td:hover:after,
thead.fixed th:hover:after {
    outline: none;
    background: transparent;
}

@media screen and (max-width: 768px) {
    .border-mobile th, .border-mobile td {
        border-left: 1px solid #e1e4e9 !important;
        border-right: 1px solid #e1e4e9 !important;
    }
}