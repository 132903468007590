.btn {
  border-radius: 500px;
  border: none;
  box-shadow: none !important;
  font-size: 1rem;
  font-weight: 600;
  outline: none !important;
  transition: 150ms;
  padding: 6px 16px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  min-height: 38px;
}

.btn-rounded {
  border-radius: 100px;
}

.btn:hover,
.btn:focus {
  /* transform: translate3d(0,-1px,0); */
  box-shadow: 0 2px 5px 0 rgba(40, 45, 53, 0.15);
}

.btn:active {
  box-shadow: none !important;
  /* transform: translate3d(0,1px,0); */
}

.btn.disabled,
.btn[disabled] {
  background-color: #b3bac5 !important;
  /* transform: translate3d(0,0,0) !important; */
}

.btn.disabled:hover,
.btn[disabled]:hover {
  box-shadow: none !important;
}

/* IE only media query */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .btn {
    transition: 0ms;
  }
}

.btn-sm {
  font-size: 11px;
  padding: 0 5px;
}

.btn-sm i {
  vertical-align: -1px;
  margin-left: -2px;
}

.btn i {
  display: inline-block;
  vertical-align: -1px;
}

.btn-xl {
  font-size: 1.15rem;
  padding: 12px 50px;
}

.btn-xl i {
  display: inline-block;
  font-size: 22px;
  line-height: 0;
  vertical-align: -3px;
}

.btn-primary-o {
  border: 1px solid #0075ff;
  color: #0075ff;
  /* transform: translate3d(0,0,0) !important; */
  box-shadow: none !important;
}

.btn-primary-o:hover,
.btn-primary-o:focus {
  border: 1px solid #0075ff;
  background: #0075ff;
  color: #fff;
  box-shadow: none !important;
}

.btn-primary-o:active {
  border: 1px solid #006acd;
  background: #006acd;
  color: #fff;
  box-shadow: none !important;
}

.btn-secondary {
  background: #EBEDF0;
  color: #151C29 !important;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:focus-within {
  background: #E6E9ED !important;
}

.btn-o {
  border: 1px solid #D7DBE1;
  background: transparent;
  color: #000;
  box-shadow: none !important;
}

.btn-o svg {
  vertical-align: -4px;
}

.btn-o:hover,
.btn-o:focus {
  border: 1px solid #959DAB;
  background: transparent;
  color: #000;
  box-shadow: none !important;
}

.btn-o:active {
  border: 1px solid #D7DBE1;
  background: transparent;
  color: #000;
  box-shadow: none !important;
}

.btn-lg {
  padding: 9px 24px;
  height: 48px;
}

.btn-default {
  background-color: #F6F7F9 !important;
  color: #151C29 !important;
}

.btn-default:hover,
.btn-default:focus {
  background-color: #E6E9ED !important;
}

.btn-default:active {
  background-color: #D7DBE1 !important;
}

.btn-success {
  background-color: #2CC93C;
}

.btn-success:hover,
.btn-success:focus {
  background-color: #05b32a;
}

.btn-success:active {
  background-color: #00901f !important;
}

.btn-success-o {
  border: 1px solid #2F9139;
  color: #2F9139;
  /* transform: translate3d(0,0,0) !important; */
  box-shadow: none !important;
  background: transparent;
}

.btn-success-o:hover,
.btn-success-o:focus {
  border: 1px solid #2F9139;
  background: #2F9139;
  color: #fff;
  box-shadow: none !important;
}

.btn-success-o:active {
  border: 1px solid #2F9139;
  background: #2F9139;
  color: #fff;
  box-shadow: none !important;
}

.btn-primary {
  background-color: #0075ff;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #0061bb;
}

.btn-primary:active {
  background-color: #006acd;
}

.btn-teal {
  color: #fff !important;
  background-color: #18B4AB;
}
.btn-teal:hover,
.btn-teal:focus {
  background-color: #118a84;
}
.btn-teal:active {
  background-color: #118a84 !important;
}

.btn-orange {
  color: #fff !important;
  background-color: #C7700A;
}
.btn-orange:hover,
.btn-orange:focus {
  background-color: #ca750c;
}
.btn-orange:active {
  background-color: #ca750c !important;
}

.btn-purple {
  color: #fff !important;
  background-color: #9745FF;
}
.btn-purple:hover,
.btn-purple:focus {
  background-color: #6e29c9;
}
.btn-purple:active {
  background-color: #6e29c9 !important;
}

.btn-transparent {
  background: transparent !important;
  box-shadow: none !important;
  color: #fff !important;
  font-weight: normal !important;
}

.btn-transparent:hover {
  box-shadow: none !important;
  text-decoration: underline !important;
}

.btn-link-black {
  background: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
}

.btn-link-black:hover {
  box-shadow: none !important;
  background: #F6F7F9 !important;
}

.btn-link {
  color: #0075ff;
  background: transparent !important;
  border: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

.btn-link:hover,
.btn-link:focus {
  color: #0073e1;
}

.btn-link:active {
  color: #006acd !important;
}

.add-another-qb-company {
  color: #0075ff;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-another-qb-company i {
  background: #def0ff;
  border-radius: 3px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.btn-xs {
  padding: 2px 6px;
  font-size: 13px;
}

.btn-primary-light {
  background: #def0ff;
  color: #0084ff !important;
}

.btn-primary-light:hover {
  background: rgb(195, 225, 250);
}

.btn-block {
  width: 100%;
  display: block;
}